import React from "react"
import Img from "gatsby-image"
import RichTextRenderer from "../RichTextRenderer"

// Background Image component
import Background from "../backgroundImage"

const Banner = ({
  data: { bannerImage, title, subtitle, description },
  customNav,
}) => {
  return (
    <Background className={customNav ? "pt-8 md:pt-16" : ""}>
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0">
        <div className="flex flex-wrap-reverse pb-6 md:flex-no-wrap">
          <div className="w-full">
            <div className="font-bold text-white md:pt-12 siteFont">
              <h1 className="text-3xl uppercase md:text-5xl">{title}</h1>
              <h3 className="my-3 text-xl md:text-3xl">{subtitle}</h3>
            </div>
            <div className="Gray md:text-lg">
              {RichTextRenderer(description.json)}
            </div>
          </div>
          <div className="w-3/5 mt-12 lg:w-10/12">
            <img src={bannerImage.file.url} alt={bannerImage.title} />
          </div>
        </div>
        <h3 className="my-8 text-4xl font-bold text-white uppercase siteFont">
          Selected Works
        </h3>
      </div>
    </Background>
  )
}

export default Banner
