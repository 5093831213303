import React from "react"
import ShowcaseTile from "../showcaseTile"

const Work = ({ data }) => {
  return (
    <div className="max-w-6xl mx-auto md:px-8 xl:px-0">
      <div className="grid w-full grid-cols-1 gap-8 mb-8 md:grid-cols-3">
        {data.map(item => (
          <ShowcaseTile data={item} />
        ))}
      </div>
    </div>
  )
}

export default Work
