import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/OurWork/banner"
import Work from "../components/OurWork/work"
import AwardSection from "../components/awardSection"
import Slider from "../components/slider"
import ContactUs from "../components/contactUs"

// Context API data
import { AppContext } from "../store/AppContext"

const OurWork = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerImage,
    title,
    subtitle,
    description,
    workShowcase,
  } = data.work

  const bannerData = { bannerImage, title, subtitle, description }
  return (
    <Layout>
      <SEO title={ogTitle || "Our Work"} description={ogDescription} />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <Work data={workShowcase} />
      <AwardSection />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query OurWorkQuery {
    work: contentfulOurWorkPage {
      ogTitle
      ogDescription
      bannerImage {
        title
        file {
          url
        }
      }
      title
      subtitle
      description {
        json
      }
      workShowcase {
        id
        slug
        image {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default OurWork
